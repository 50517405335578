import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "shadows"
    }}>{`Shadows`}</h1>
    <p>{`CSS has classes that change the shadows variants property to maintain standardization`}</p>
    <h2 {...{
      "id": "box-shadow"
    }}>{`Box Shadow`}</h2>
    <p>{`Apply `}<inlineCode parentName="p">{`box-shadow: 0px 4px 12px -5px var(--gray)`}</inlineCode></p>
    <div className='box box-shadow'>
  Box Content
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class='box-shadow'>...</div>
`}</code></pre>
    <h2 {...{
      "id": "text-shadow"
    }}>{`Text Shadow`}</h2>
    <p>{`Apply `}<inlineCode parentName="p">{`text-shadow:: 0px 4px 12px var(--gray)`}</inlineCode></p>
    <div className='box text-shadow box-shadow-none'>
  Box Content
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class='text-shadow'>...</div>
`}</code></pre>
    <h2 {...{
      "id": "drop-shadow"
    }}>{`Drop Shadow`}</h2>
    <p>{`Apply `}<inlineCode parentName="p">{`filter: drop-shadow(0px 4px 12px var(--gray))`}</inlineCode></p>
    <div style={{
      "maxWidth": "300px"
    }}>
  <img src='https://portal.squidit.com.br/assets/img/jpg/default-image.jpg' alt='Default Image' title='Default Image' className='img-fluid drop-shadow' />
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<img class='drop-shadow' src='...'>
`}</code></pre>
    <h2 {...{
      "id": "reset"
    }}>{`Reset`}</h2>
    <p>{`To reset any shadow applied use the following classes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class='box-shadow-none'>...</div>
<div class='text-shadow-none'>...</div>
<div class='drop-shadow-none'>...</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      